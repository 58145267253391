







































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import { IVisitorsParams, IVisitor } from '@/models/visitor';
import store from '@/store';
import router from '@/router';
import UserCard from '@/partials/UserCard.vue';
import Input from '@/components/form/input.vue';
import { IUserCard } from '@/partials';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import { manageVisitorFilters, manageVisitorFiltersModel } from '.';

export default defineComponent({
  components: {
    UserCard,
    Input,
  },
  data() {
    return {
      event: 0,
      visitors: [] as IVisitor[],
      filters: manageVisitorFilters,
      model: manageVisitorFiltersModel,
      searchTimeout: 0,
    };
  },
  setup() {
    const { translations } = useContext();
    const visitorsState = computed(() => store.getters.getVisitorsState);
    return {
      translations,
      visitorsState,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    const userRolesSet = store.getters.getUserRolesSet as boolean;

    this.filters.search.onInput = this.searchVisitors;

    if (userRolesSet) {
      this.getVisitors();
    } else {
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setRoles') {
          this.getVisitors();
        }
      });
    }
  },
  methods: {
    searchVisitors(value: string | number) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.visitors = [];
        const params: IVisitorsParams = {
          event: this.event,
          search: value as string,
          page: 1,
          page_size: 20,
          reset: true,
        };
        store.dispatch('fetchVisitors', params);
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setVisitors') {
            this.visitors = mutation.payload.list;
            unsubscribe();
          }
        });
      }, 1000);
    },

    nextPage() {
      if (this.visitorsState.page) {
        const params: IVisitorsParams = {
          page: this.visitorsState.page + 1,
          page_size: 100,
        };

        if (this.model.search !== '') {
          params.search = this.model.search;
        }

        store.dispatch('fetchVisitors', params);
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setVisitors') {
            this.visitors = [...this.visitors, ...mutation.payload.list];
            unsubscribe();
          }
        });
      }
    },
    getVisitors() {
      const userAdmin = store.getters.userAdmin as boolean;
      const userManages = store.getters.userManages as number[];
      const relatedVisitors = store.getters.getRelatedVisitors as IVisitor[];
      const userManagesThisEvent =
        userManages.length > 0 && userManages.some((eventId) => eventId === this.event);
      if (userAdmin || userManagesThisEvent) {
        const params: IVisitorsParams = {
          event: this.event,
          page: 1,
          page_size: 20,
        };
        store.dispatch('fetchVisitors', params);
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setVisitors') {
            this.visitors = mutation.payload.list;
            unsubscribe();
          }
        });
      } else if (relatedVisitors.length > 0) {
        this.visitors = relatedVisitors.filter((visitor) => this.event === visitor.event);
      } else {
        router.push('/events');
      }
    },

    cardConfig(visitor: IVisitor) {
      const config: IUserCard = {
        name: visitor.name,
        detail: visitor.email,
        picture: visitor.profile_picture,
        redirect: `/event/${this.event}?visitor=${visitor.id}}`,
      };
      return config;
    },
  },
});
